import React, { useEffect } from 'react'
import Alert from 'react-bootstrap/Alert'
import { useSearchParams } from 'react-router-dom'
import { signal } from '@preact/signals-react'
import { useCookies } from 'react-cookie'
import { Trans, useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { LoginPage, StoreManagementPage } from './pages'

import api from 'corporate/api'

export const user = {
  details: signal(null),
  isLoading: signal(false),
  refreshToken: signal(null),
  token: signal(null),
}

export const refetchUser = (token, removeCookie = () => null) => {
  if (!token) return false

  user.isLoading.value = true
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`

  api.get('/api/v2/user')
    .then(({ data }) => {
      user.details.value = data.data

      localStorage.setItem('uuid', user.details.value.id)
    })
    .catch((error) => {
      if (window.__REFRESH_TOKEN__) {
        api.post('/api/v2/oauth/token', {
          client_id: 'webapp',
          grant_type: 'refresh_token',
          refresh_token: window.__REFRESH_TOKEN__,
        })
          .then(({ data }) => {
            window.__ACCESS_TOKEN__ = data.access_token
            window.__REFRESH_TOKEN__ = data.refresh_token

            refetchUser(data.access_token)
          })
          .catch((error) => {
            user.details.value = null
          })
          .finally((error) => {
            user.isLoading.value = false
          })
      } else {
        removeCookie('token')
        removeCookie('refreshToken')

        user.details.value = null
      }

    })
    .finally((error) => {
      user.isLoading.value = false
    })
}

const CorporateApp = () => {
  console.log(window.__ACCESS_TOKEN__, window.__REFRESH_TOKEN__)
  const [cookies, , removeCookie] = useCookies(['corporate_token', 'corporate_refreshToken'])
  const [searchParams] = useSearchParams()
  const { i18n } = useTranslation()
  const uuid = searchParams.get('uuid')

  const countyCodes = {
    fr: 'fr-FR',
    jp: 'ja-JP',
    us: 'en-US',
  }

  useEffect(() => {
    if (window.__ACCESS_TOKEN__) {
      refetchUser(window.__ACCESS_TOKEN__)

      return null
    }

    if (cookies.corporate_token) {
      const storedUuid = localStorage.getItem('uuid')

      if (!uuid || storedUuid === uuid)
        refetchUser(cookies.corporate_token, removeCookie)
    }
  }, [])

  const countryKey = searchParams.get('country')

  if (user.isLoading.value)
    return null

  if (user.details.value) {
    if (countyCodes[user.details.value.country_code] !== i18n.language)
      i18n.changeLanguage(countyCodes[user.details.value.country_code])
  } else {
    if (!isEmpty(countryKey) && countyCodes[countryKey] !== i18n.language)
      i18n.changeLanguage(countyCodes[countryKey])
  }

  return (
    <div className="container mx-auto align-item-center">
      <div className="row">
        <div className="col-12 mb-150">

          {user.details.value && <StoreManagementPage />}
          {!user.details.value && uuid && <LoginPage />}

          {!user.details.value && !uuid &&
            <Alert variant="danger">
              <Trans>loginError</Trans>
            </Alert>
          }
        </div>
      </div>
    </div>
  )
}

export default CorporateApp
