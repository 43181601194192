/*eslint no-template-curly-in-string: "off"*/
/*eslint sort-keys: "off"*/

const translates = {
  comment: 'Comment',
  enterValidPsw: '有効なパスワードを入力してください',
  helpUsKeepThisAccountSafe: 'このアカウントの安全確保にご協力ください',
  login: 'ログイン',
  password: 'パスワード',
  menuFaq: 'よくある質問',
  menuOrderTote: '新しいトートを注文する',
  body: '本文',
  emptySubjectOrBody: '件名と本文を記入してください',
  messageSent: '送信完了',
  subject: '件名',
  sendEmail: '送信',
  errorMsgTitle: 'エラーが発生しました',
  missingUuid: 'ユーザーIDが不明です',
  howWeCanHelp: 'よくある質問',
  need_help: 'その他のお問い合わせ',
  loading: '読み込み中',
  loginFailed: 'Login Failed',
  loginFailedMessage: 'ログインに失敗しました',
  back: '戻る',
  backToStoreManagement: '店舗管理ページに戻る',
  succesMsgTitle: '完了！',
  success: '完了！',
  receivedInfoModification: 'リクエストを受け付けました。カスタマーサービスからの連絡をお待ちください。',
  receivedToteOrder: 'リクエストを受け付けました。カスタマーサービスからの連絡をお待ちください。',
  emailAddress: 'メールアドレス',
  numberOfTotes: 'トートの数',
  modificationRequestError: 'リクエストエラー',
  myInformationIsIncorrect: '情報が間違っています',
  orderRequestError: '注文ができません',
  orderRequestSent: '注文完了',
  orderTotes: 'トートを注文する',
  phoneNumber: '電話番号',
  phoneNumberUpdateError: 'アップデートに失敗しました',
  phoneNumberUpdateSuccess: 'アップデートに成功しました',
  pleaseConfirm: '注文情報を確認してください',
  sendUsModification: '修正を依頼する',
  storeAddress: '店舗の住所',
  menuContact: 'お問い合わせ',
  clickToEdit: '編集する',
  loginError: ' Your token is expired or revoked. Please refresh the page',
}

export default translates

