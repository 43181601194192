import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { CheckOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Input, List, notification, Radio, Result, Typography } from 'antd'
import { Trans } from 'react-i18next'
import { BackButton } from '../../elements'

import { user } from 'corporate/App'

import api from 'corporate/api'

const { Paragraph, Title } = Typography
const { Search, TextArea } = Input

const OrderNewTote = () => {
  const navigate = useNavigate()

  const phoneNumberRef = useRef(null)
  const commentRef = useRef(null)

  const [result, setResult] = useState(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [value, setValue] = useState(5)

  const options = [5, 10]

  useEffect(() => {
    if (isEditing)
      phoneNumberRef.current.focus()
  }, [isEditing])

  const handleConfirm = () => {
    const comment = commentRef.current.resizableTextArea.textArea.value

    setIsLoading(true)

    api.post('/api/v2/corporate/tote_order', {
      comment,
      number: value,
    })
      .then(() => {
        setResult({
          descriptionKey: 'receivedToteOrder',
          status: 'success',
          titleKey: 'success',
        })

      })
      .catch((error) => {
        setIsLoading(false)

        notification.error({
          description: <Trans>orderRequestError</Trans>,
          duration: 5,
          message: <Trans>errorMsgTitle</Trans>,
          placement: 'bottom',
        })
      })
  }

  const saveNewPhoneNumber = () => {
    const phoneValue = phoneNumberRef.current.input.value.replace(/\D/g,'')

    if (phoneValue === user.details.value.store.contact_phone) {
      setIsEditing(false)

      return false
    }

    const fields = {
      contact_phone: phoneValue,
    }

    api.put('/api/v2/corporate/store', fields)
      .then(({ data }) => {
        notification.success({
          description: <Trans>phoneNumberUpdateSuccess</Trans>,
          duration: 5,
          message: <Trans>succesMsgTitle</Trans>,
          placement: 'bottom',
        })

        user.details.value = {
          ...user.details.value,
          store: data.data,
        }

        setIsEditing(false)
      })
      .catch(() => {
        setIsLoading(false)

        notification.error({
          description: <Trans>phoneNumberUpdateError</Trans>,
          duration: 5,
          message: <Trans>errorMsgTitle</Trans>,
          placement: 'bottom',
        })
      })
  }

  const modificationRequest = () => {
    api.post('/api/v2/corporate/incorrect_store_data')
      .then(() => {
        setResult({
          descriptionKey: 'receivedInfoModification',
          status: 'success',
          titleKey: 'success',
        })
      })
      .catch(() => {
        setIsLoading(false)

        notification.error({
          description: <Trans>modificationRequestError</Trans>,
          duration: 5,
          message: <Trans>errorMsgTitle</Trans>,
          placement: 'bottom',
        })
      })
  }

  const userData = [
    {
      titleKey: 'phoneNumber',
      value:
        (
          isEditing
            ?<Search
              className="custom-ant-input-search "
              defaultValue={user.details.value.store.contact_phone}
              enterButton={<CheckOutlined />}
              onInput={e => e.target.value = e.target.value.replace(/\D/g,'')}
              onSearch={saveNewPhoneNumber}
              ref={phoneNumberRef}
              size="large"
            />
            : (
              <Paragraph className="inline-edit">
                {user.details.value.store.contact_phone} <EditOutlined onClick={() => setIsEditing(true)} />
              </Paragraph>
            )
        ),
    },
    {
      titleKey: 'storeAddress',
      value: (
        <>
          <p><b>{user.details.value.store.name || ''}</b></p>
          <p>{`${user.details.value.store.country_name || ''}, ${user.details.value.store.zip_code || ''} ${user.details.value.store.city || ''}`}</p>
          <p>{user.details.value.store.address_line || ''}</p>
        </>
      ),
    },
    {
      titleKey: 'numberOfTotes',
      value: (
        <Radio.Group className="orderTotes" onChange={(e) => setValue(e.target.value)} options={options} optionType="button" value={value} />
      ),
    },
    {
      titleKey: 'comment',
      value: (
        <TextArea autoFocus className="order-comment" placeholder=" " ref={commentRef} rows={4} />
      ),
    },
    {
      titleKey: 'emailAddress',
      value: user.details.value.store.reporting_email || '-',
    },
  ]

  if (!isEmpty(result))
    return (
      <Result
        extra={[
          <Button className="menu-element w-100" key="backButton" onClick={() => navigate('/')} shape="round" size="large" type="primary">
            <Trans>backToStoreManagement</Trans>
          </Button>,
        ]}
        status="success"
        subTitle={<Trans>{result.descriptionKey}</Trans>}
        title={<Trans>{result.titleKey}</Trans>}
      />
    )

  return (
    <div className="faq-card">
      <Title className="align-item-center primary-color" level={5}>
        <Trans>pleaseConfirm</Trans>
      </Title>

      <List
        dataSource={userData}
        itemLayout="horizontal"
        renderItem={({ titleKey, value }, index) => (
          <List.Item>
            <List.Item.Meta
              description={value}
              title={<Trans>{titleKey}</Trans>}
            />
          </List.Item>
        )}
      />

      <div className="btn-wrapper">
        <Button
          className="menu-element w-100"
          disabled={isLoading}
          loading={isLoading}
          onClick={handleConfirm}
          shape="round"
          size="large"
          type="primary"
        >
          <Trans>orderTotes</Trans>
        </Button>

        <BackButton />

        <Title className="align-item-center mt-50" level={5}>
          <Trans>myInformationIsIncorrect</Trans>
        </Title>

        <Button className="menu-element w-100" onClick={modificationRequest} shape="round" size="large" type="primary">
          <Trans>sendUsModification</Trans>
        </Button>
      </div>
    </div>
  )
}

export default OrderNewTote
